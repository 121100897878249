<template>
  <div :class="['register-comp-controls', { noBackground, noBorder }]">
    <r-block
      no-padding
      :gap="0"
      width="240px"
    >
      <r-search-input
        :placeholder="filterPlaceholder"
        :filter-text="filterText"
        @change="$emit('handle-filter-text', $event)"
      />
    </r-block>
    <r-filter-set
      :filters="filters"
      :no-background="noBackground"
      @reset-all="$emit('reset-filters')"
      @load="$emit('load-filter', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      default: () => null
    },
    filterText: {
      type: String,
      default: ''
    },
    filterPlaceholder: {
      type: String,
      default: 'Поиск по номеру акта'
    },
    noBackground: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.register-comp-controls {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  padding: 0 0.5rem;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid var(--dividers_low_contrast);

  &.noBackground {
    background-color: transparent;
  }

  &.noBackground {
    border: none;
  }
}
</style>
